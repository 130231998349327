import React, { Component } from 'react';
import Popup from './Popup';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
        };
    }

    openInfoPopupHandler(){
        this.setState({showPopup: true});
    }

    closePopupHandler(){
        this.setState({showPopup: false});
    }


    render() {
        let popup;
        let icon;

        if(this.state.showPopup)
            popup = <Popup title={this.props.item.name}
                           cancelTitle="zamknij objaśnienie"
                           cancelHandler={this.closePopupHandler.bind(this)}><p>{this.props.item.description}</p></Popup>

        if(this.props.item.description)
            icon = <div className="package-info__icon"><button type="button" onClick={this.openInfoPopupHandler.bind(this)}><img src="/img/information.svg" alt="info"/></button></div>

        return (
            <React.Fragment>
                <div className="package-info">
                    <div className="package-info__description">
                        <div className="package-info__name">{this.props.item.name}</div>
                        <div className="package-info__price">{this.props.item.price} zł</div>
                        <div className="package-info__time">Pakiet ważny do końca miesiąca.</div>
                    </div>

                    {icon}

                </div>
                {popup}
            </React.Fragment>
        );
    }
}

export default App;