import React, { Component } from 'react';
import Welcome1 from './welcome/Welcome1';
import Welcome2 from './welcome/Welcome2';

class App extends Component {

    constructor(props){
        super(props);

        this.state = {
            step1: true,
            phone: null
        };
    }

    setPhone(phone){
        this.setState({
            step1: false,
            phone: phone
        });
    }

    gotToStep1(){
        this.setState({
            step1: true,
            phone: null
        });
    }

    render() {

        const CONTENT = this.state.step1?<Welcome1 setPhone={this.setPhone.bind(this)}/>:<Welcome2 phone={this.state.phone} gotToStep1={this.gotToStep1.bind(this)} />;

        return (
            <React.Fragment>
            {CONTENT}
            </React.Fragment>
        );
    }

}

export default App;
