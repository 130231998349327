import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Layout from '../../components/WelcomeLayout';

class App extends Component {

    render() {


        return (

            <Layout title="Konto zablokowane" subtitle="Twoje konto zostało pomyślnie zablokowane. W celu ponownej aktywacji numeru telefonu, skontaktuj się z naszym Biurem Obsługi Klienta." icon="shield">
                <footer>
                    <p><Link to="/" className="link link--olive">Powrót do logowania</Link></p>
                </footer>
            </Layout>

        );
    }

}

export default App;
