export const authorization = (dispatch, token_id, phone) => {
    if (token_id && phone) {
        dispatch({
            type: 'AUTHORIZATION',
            token_id,
            phone
        });
        global.localStorage.setItem('token_id', token_id);
        global.localStorage.setItem('phone', phone);
        const mobilesFromLocalStorage = global.localStorage.getItem('mobiles') ? JSON.parse(global.localStorage.getItem('mobiles')) : [],
            mobiles = [],
            isMobileSelected = mobilesFromLocalStorage.filter((mobile) => {
                return mobile.selected;
            });
        if (!isMobileSelected.length) {
            mobilesFromLocalStorage.forEach((mobile) => {
                mobiles.push({
                    'id': mobile.id,
                    'phoneNumber': mobile.phoneNumber,
                    'master': mobile.master,
                    'selected': parseInt(mobile.phoneNumber) === parseInt(phone)
                });
            });
            global.localStorage.setItem('mobiles', JSON.stringify(mobiles));
        }
    }
}

export const unauthorization = (dispatch) => {
    dispatch({
        type: 'UNAUTHORIZATION'
    });
}