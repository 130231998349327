import React, { Component } from 'react';
import Card from './../../components/Card'

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    phone(){
        return "tel:"+this.props.partner_data.phone_raw;
    }

    email(){
        return "mailto:"+this.props.partner_data.email;
    }

    render() {

        const PACKETS = [];


        return (
            <React.Fragment>
                <h1 className="card--size-1-of-1">Pomoc techniczna</h1>

                <Card >
                    <p>Jeśli masz problemy związane z działaniem aplikacji lub masz pytania dotyczące usług dodatkowych, skontaktuj się z naszym Biurem Obsługi Klienta.</p>
                    <p><b>{this.props.partner_data.name}</b></p>
                    <p>tel: <a href={this.phone()}>{this.props.partner_data.phone}</a></p>
                    <p>e-mail: <a href={this.email()}>{this.props.partner_data.email}</a></p>
                </Card>



                {PACKETS}

            </React.Fragment>
        );
    }
}


export default App;