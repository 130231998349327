import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Layout from '../../components/WelcomeLayout';
import Error from './../Error';
import RestApi from './../../helper/RestActions';

class App extends Component {

    constructor(props){
        super(props);

        this.state = {
            phone: '',
            phoneValidMessage: null,
            isPhoneValid: true,
            error: null
        };
    }

    validatePhoneNumber(callback){

        if(!this.state.phone){
            callback(false);
            return;
        }

        RestApi.validatePhone(this.state.phone,(response)=>{
            callback(response.data);
        }, (error)=>{
            this.setState({
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        } )
    }

    submitHandler(e){
        this.validatePhoneNumber((result)=>{
            if(!result.valid) {
                this.setState({
                    isPhoneValid: false,
                    phoneValidMessage: result.message
                }, () => setTimeout(() => this.setState({
                    isPhoneValid: true,
                    phoneValidMessage: null
                }), 3000));
            } else {
                this.props.setPhone(this.state.phone);
            }
        });


        e.stopPropagation();
        e.preventDefault();
    }

    inputPhoneHandler(e){

        const value = parseInt(e.target.value,10);

        if(Number.isInteger(value) && parseInt(value)>0)
            this.setState({
                phone: value
            });
        else {
            this.setState({
                phone: ''
            });
        }
    }

    renderMessage(){
        return (
            <div className="success">
                <img src="/img/exclamation-mark.svg" alt="" aria-hidden="true" />
                <p>{this.state.phoneValidMessage}</p>
            </div>
        )
    }

    render() {

        if(this.state.error)
            return(
                <Error title={this.state.error.title} subtitle={this.state.error.subtitle} />
            );

        const PHONE_CLASS = ['field'];
        if(!this.state.isPhoneValid) {
            PHONE_CLASS.push('field--error');
            PHONE_CLASS.push('shake');
            PHONE_CLASS.push('animated');
        }

        const FORM_FOOTER = this.state.phoneValidMessage?this.renderMessage():'';

        return (

            <Layout title="Blokada - krok 1" subtitle="Zgubiłeś swój telefon lub został skradziony? Podaj swój numer telefonu. Na Twój adres e-mail wyślemy kod potwierdzający blokadę.">
                <form className="form" onSubmit={this.submitHandler.bind(this)}>

                    <div className={PHONE_CLASS.join(' ')}>
                        <label htmlFor="">Numer telefonu</label>
                        <input type="tel" value={this.state.phone} placeholder="Numer telefonu" maxLength="9" onChange={this.inputPhoneHandler.bind(this)} />
                    </div>

                    <div className="action">
                        <button type="submit">Wyślij kod <img src="/img/send.svg" alt="" aria-hidden="true"/></button>
                    </div>

                    {FORM_FOOTER}
                </form>


                <footer>
                    <p><Link to="/" className="link link--olive">Powrót do logowania</Link></p>
                </footer>
            </Layout>

        );
    }

}

export default App;
