import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Welcome from './screens/Welcome'
import Ban from './screens/Ban'
import Login from './screens/Login'
import MyAccount from './screens/MyAccount'
import NotFound from './screens/NotFound'
import Logout from './screens/Logout'

import {connect} from "react-redux";
import {authorization} from "./store/actions";

class App extends Component {


    constructor(props) {
        super(props);

        this.state = {
            showInstallMessage: false
        };
    }

    componentWillMount() {
        if (global.localStorage.getItem('phone') && global.localStorage.getItem('token_id')) {
            //todo: do poprawy
            this.props.authorization(global.localStorage.getItem('token_id'), global.localStorage.getItem('phone'));
        }

        // Detects if device is on iOS
        const isIos = () => {
            const userAgent = global.window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        }
        // Detects if device is in standalone mode
        const isInStandaloneMode = () => ('standalone' in global.window.navigator) && (global.window.navigator.standalone);

        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode()) {
            this.setState({showInstallMessage: true});
        }
    }

    closeInstallBannerHandler() {
        this.setState({showInstallMessage: false});
    }

    isLogged() {
        return (
            <Switch>
                <Route exact path="/" component={MyAccount}/>
                <Route path="/zaloguj" component={MyAccount}/>
                <Route path="/blokada-konta" component={Ban}/>
                <Route path="/moje-konto" component={MyAccount}/>
                <Route path="/wyloguj" component={Logout}/>
                <Route component={NotFound}/>
            </Switch>
        );
    }

    isNotLogged() {
        return (
            <Switch>
                <Route exact path="/" component={Welcome}/>
                <Route path="/blokada-konta" component={Ban}/>
                <Route path="/zaloguj" component={Login}/>
                <Route path="/wyloguj" component={Logout}/>
                <Route component={NotFound}/>
            </Switch>
        );
    }

    renderInstallMessage() {
        return (
            <div className="install-banner">
                <button type="button" onClick={this.closeInstallBannerHandler.bind(this)}><img
                    src="/img/times-solid.svg" alt="Zamknij"/></button>
                <p>Aby zainstalować aplikację na twoim iPhone, tapnij na <img src="/img/iphone.png" alt=""/> ,a nstępnie
                    dodaj do ekranu stratowego,</p>
            </div>
        )
    }

    render() {

        const ROUTES = this.props.token ? this.isLogged() : this.isNotLogged(),

            BANNER = this.state.showInstallMessage ? this.renderInstallMessage() : '';

        return (
            <Router>
                <div className="App">
                    {ROUTES}
                    {BANNER}
                </div>
            </Router>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        token: state.auth.token_id
    }
};


const mapDispatchToProps = dispatch => {
    return {
        authorization: (token_id, phone) => authorization(dispatch, token_id, phone),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
