import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Layout from '../../components/WelcomeLayout';
import Error from './../Error';
import RestApi from './../../helper/RestActions';

class App extends Component {

    constructor(props){
        super(props);

        this.state = {
            code: '',
            error: null,
            message: '',
            isCodeValid: true,
            codeResendClicked: false,
            sendCodeResult: false
        };
    }

    validateCode(callback){
        //todo: walidacja po stronie serwera
        if(this.state.code.length < 5){
            callback(false);
            return;
        }

        RestApi.banAccount(this.props.phone,this.state.code,(response)=>{
            callback(response.data.status);
        }, (error)=>{ //console.log(error)
            this.setState({
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        } )
    }

    submitHandler(e){
        this.validateCode((valid)=>{
            if(!valid) {
                this.setState({
                    isCodeValid: false
                }, () => setTimeout(() => this.setState({
                    isCodeValid: true
                }), 2000));
            } else {
                this.props.goToSuccess();
            }
        });

        e.stopPropagation();
        e.preventDefault();
    }

    inputCodeHandler(e){
        this.setState({
            code: e.currentTarget.value
        });
    }

    sendAgainHandler(){

        const callback = (response)=>{

            if(response.status === false){
                this.setState({
                    'error': {
                        'title': "",
                        'subtitle': response.message
                    }
                });
            }
            else {
                this.setState({
                    codeResendClicked: true,
                    message: response.message
                });
            }
        }

        this.sendCode(callback);
    }

    sendCode(callback){

        RestApi.emailCode(this.props.phone, (response)=>{
            callback(response.data)
        }, (error)=>{
            this.setState({
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': ''
                }
            });
        } )
    }


    componentWillMount() {

        const callback = (response)=>{

            if(response.status === false){
                this.setState({
                    'error': {
                        'title': "",
                        'subtitle': response.message
                    },
                    sendCodeResult: true
                });
            } else{
                this.setState({
                    message: response.message,
                    sendCodeResult: true
                });
            }
        }

        this.sendCode(callback);
    }

    renderSuccess(){
        return (
            <div className="success">
                <img src="/img/checked.svg" alt="" aria-hidden="true" />
                    <p>{this.state.message}</p>
            </div>
        )
    }

    renderRessendInfo(){
        return (
            <div className="form__footer">
                <p>Nie otrzymałeś od nas wiadomości?<br />
                    <button type="button" className="link link--olive" onClick={this.sendAgainHandler.bind(this)}>Wyślij kod ponownie</button></p>
            </div>
        );
    }

    render() {

        if(this.state.error)
            return(
                <Error title={this.state.error.title} subtitle={this.state.error.subtitle} />
            );

        const PHONE_CLASS = ['field'];
        if(!this.state.isCodeValid) {
            PHONE_CLASS.push('field--error');
            PHONE_CLASS.push('shake');
            PHONE_CLASS.push('animated');
        }

        const FORM_FOOTER = this.state.codeResendClicked?this.renderSuccess():this.renderRessendInfo();

        if(this.state.sendCodeResult)
            return (

                <Layout title="Blokada - krok 2" subtitle="Wpisz 4-cyfrowy kod potwierdzający blokadę Twojego numeru.">
                    <form className="form" onSubmit={this.submitHandler.bind(this)}>

                        <div className={PHONE_CLASS.join(' ')}>
                            <label htmlFor="">Kod blokady</label>
                            <input type="tel" value={this.state.code} placeholder="Wpisz kod blokady" maxLength="5" onChange={this.inputCodeHandler.bind(this)} />
                        </div>

                        <div className="action">
                            <button type="submit">Zablokuj konto <img src="/img/send.svg" alt="" aria-hidden="true"/></button>
                        </div>

                        {FORM_FOOTER}
                    </form>


                    <footer>
                        <p><Link to="/" className="link link--olive">Powrót do logowania</Link></p>
                    </footer>
                </Layout>

            );
        else return (

            <Layout title="Blokada - krok 2" subtitle="Walidacja danych.">
                <div className="form">

                    <div className="success">

                        <p>Trwa walidacja danych - proszę czekać.</p>
                    </div>
                </div>


                <footer>
                    <p><Link to="/" className="link link--olive">Powrót do logowania</Link></p>
                </footer>
            </Layout>

        )
    }

}

export default App;
