import React, {Component} from 'react';
// import { Redirect } from "react-router";
import Layout from './../../components/WelcomeLayout';
import Error from './../Error';
import RestApi from './../../helper/RestActions';

import {connect} from "react-redux";
import {authorization} from './../../store/actions';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            code: '',
            error: null,
            message: '',
            codeResendClicked: false,
            isCodeValid: true,
            time: null
        };
    }

    validateCode(callback) {
        //todo: walidacja po stronie serwera
        if (this.state.code.length < 5) {
            callback(false);
            return;
        }

        RestApi.activateAccount(this.props.phone, this.state.code, (response) => {
            callback(response.data);
        }, (error) => {
            this.setState({
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        })
    }

    submitHandler(e) {
        this.validateCode((result) => {
            if (!result.status) {
                this.setState({
                    isCodeValid: false
                }, () => setTimeout(() => this.setState({
                    isCodeValid: true
                }), 2000));
            } else {
                global.localStorage.setItem('mobiles', JSON.stringify(result.mobiles))
                this.props.authorization(result.token, this.props.phone);
            }
        });

        e.stopPropagation();
        e.preventDefault();
    }

    inputCodeHandler(e) {
        this.setState({
            code: e.currentTarget.value
        });
    }

    sendAgainHandler() {

        const callback = (response) => {
            this.setState({
                codeResendClicked: true,
                message: response.message
            });
        }

        this.sendCode(callback);
    }

    sendCode(callback) {
        const time = Date.now();
        this.setState({
            time: time
        });

        RestApi.sendCode(this.props.phone, time, (response) => {
            callback(response.data)
        }, (error) => {
            this.setState({
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': ''
                },
                time: null
            });
        })
    }

    componentWillMount() {

        const callback = (response) => {
            if (!response.status) {
                this.setState({
                    message: response.message
                });
            }
        }

        this.sendCode(callback);
    }

    renderSuccess() {
        return (
            <div className="success">
                <img src="/img/checked.svg" alt="" aria-hidden="true"/>
                <p>{this.state.message}</p>
            </div>
        )
    }

    renderRessendInfo() {
        return (
            <div className="form__footer">
                <p>Nie otrzymałeś od nas wiadomości?<br/>
                    <button type="button" className="link link--olive" onClick={this.sendAgainHandler.bind(this)}>Wyślij
                        kod ponownie
                    </button>
                </p>
            </div>
        );
    }

    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    formatDate(date) {
        return (
            [
                date.getFullYear(),
                this.padTo2Digits(date.getMonth() + 1),
                this.padTo2Digits(date.getDate()),
            ].join('-') +
            ' ' +
            [
                this.padTo2Digits(date.getHours()),
                this.padTo2Digits(date.getMinutes()),
                this.padTo2Digits(date.getSeconds()),
            ].join(':')
        );
    }

    render() {

        if (this.state.error)
            return (
                <Error title={this.state.error.title} subtitle={this.state.error.subtitle}/>
            );

        const PHONE_CLASS = ['field'];
        if (!this.state.isCodeValid) {
            PHONE_CLASS.push('field--error');
            PHONE_CLASS.push('shake');
            PHONE_CLASS.push('animated');
        }

        const FORM_FOOTER = this.state.codeResendClicked ? this.renderSuccess() : this.renderRessendInfo();

        return (

            <Layout title="Krok 2"
                    subtitle="Wpisz kod autoryzacyjny" subtitle2={this.state.time ? 'wyslany ' + this.formatDate(new Date(this.state.time)) : ''}>
                <form className="form" onSubmit={this.submitHandler.bind(this)}>

                    <div className={PHONE_CLASS.join(' ')}>
                        <label htmlFor="">Wpisz kod aktywacyjny</label>
                        <input type="tel" value={this.state.code} placeholder="Wpisz jednorazowy kod" maxLength="5"
                               onChange={this.inputCodeHandler.bind(this)}/>
                    </div>

                    <div className="action">
                        <button type="submit">Aktywuj konto <img src="/img/send.svg" alt="" aria-hidden="true"/>
                        </button>
                    </div>

                    {FORM_FOOTER}

                </form>


                <footer>
                    <p>
                        <button type="button" className="link link--olive" onClick={this.props.gotToStep1}>Zmień numer
                            telefonu
                        </button>
                    </p>
                </footer>
            </Layout>

        );
    }

}


const mapStateToProps = (state) => {
    return {}
};


const mapDispatchToProps = dispatch => {
    return {
        authorization: (token_id, phone) => authorization(dispatch, token_id, phone),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
