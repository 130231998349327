import React, { Component } from 'react';
import Packet from './../../components/Packet';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            progress: 0
        };
    }


    getInternetData(){
        return this.props.internet.used + ' '+ this.props.internet.unit;
    }

    componentDidMount() {

        if(this.props.internet && this.props.internet.used && this.props.internet.total) {

            let value = (this.props.internet.used / this.props.internet.total) * 100;
            if (value > 100)
                value = 100;

            setTimeout(() => {
                this.setState({progress: value});
            }, 500);
        }
    }

    renderProggress(){

        const progress = {
            width: this.state.progress+"%"
        };

        if(this.props.internet.unit === null && this.props.internet.used === null)
            return false;

        return (
            <div className="progress-bar-field">
                <h3 className="progress-bar-field__title">Wykorzystanie danych</h3>
                <div className="progress-bar-field__bg">
                    <div className="progress-bar-field__bar" style={progress}></div>
                </div>
                <div className="progress-bar-field__value"><b>{this.props.internet.used}{this.props.internet.unit}</b> / {this.props.internet.total}{this.props.internet.unit}</div>
                <div className="progress-bar-field__info">Pakiet zostanie odnowiony
                    za {this.props.internet.end} dni
                </div>
            </div>
        );
    }

    render() {
        const PACKETS = [];
        const PACKET_PROGRESS = [];

        // if(this.props.internet && this.props.internet.purchased.total)
        if(this.props.internet)
            PACKET_PROGRESS.push(this.renderProggress());

        if(!this.props.purchased_packages){
            return (
                <React.Fragment>
                    <h1 className="card--size-1-of-1">Aktywne pakiety dodatkowe</h1>
                    <section className="card--size-1-of-1 card">
                        <div className="card__body">
                            <p>Brak wykupionych pakietów.</p>
                        </div>
                    </section>
                </React.Fragment>
            );
        }

        if(this.props.purchased_packages.length) {
            this.props.purchased_packages.forEach((item,key)=>{
                PACKETS.push(<Packet item={item} key={key}/>);
            });
        }

        return (
            <React.Fragment>
                <h1 className="card--size-1-of-1">Wykorzystanie danych w tym miesiącu</h1>
                <section className="card--size-1-of-1 card">
                    <div className="card__body">
                        <div className="packages-info">
                            {PACKET_PROGRESS}
                        </div>
                    </div>
                </section>
                <h1 className="card--size-1-of-1 offset-top">Aktywne pakiety dodatkowe</h1>
                <section className="card--size-1-of-1 card">
                    <div className="card__body">
                        <div className="packages-info packages-info--small">
                            {PACKETS}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default App;