import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Layout from '../components/WelcomeLayout';
import {connect} from "react-redux";
import {authorization} from './../store/actions';

class App extends Component {

    componentWillMount() {
        global.localStorage.clear();
        this.props.authorization(null, null);
        // global.location.reload();
        global.window.open("/", "_self");
    }

    render() {
        return (
            <Layout title="Wylogowano" subtitle="Zostałeś wylogowany">
                <footer>
                    <p><Link to="/" className="link link--olive">Powrót do logowania</Link></p>
                </footer>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token_id
    }
};

const mapDispatchToProps = dispatch => {
    return {
        authorization: (token_id, phone) => authorization(dispatch, token_id, phone),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
