import React, { Component } from 'react';
import Popup from './Popup';
import RestApi from "../helper/RestActions";

class App extends Component {

    constructor(props) {
        super(props);

        let phone = "";
        if(this.props.item && this.props.user_data && this.props.user_data.redirect && this.props.user_data.redirect_phones)
            phone = this.props.user_data.redirect_phones[this.props.item.id];

        this.state = {
            showPopup: false,
            showLoadingPopup: false,
            showPopupRedirection: false,
            showRenderPopup: false,
            validPhone: true,
            phone: phone
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if(!this.state.validPhone) {
            setTimeout(() => this.setState({validPhone: true}), 1500);
        }
    }

    openInfoPopupHandler(){
        this.setState({showPopup: true});
    }

    showLoadingPopup(){
        this.setState({showLoadingPopup: true});
    }
    showRenderPopup(){
        this.setState({showRenderPopup: true});
    }

    closePopupHandler(){
        this.setState({
            showPopup: false,
            showPopupRedirection: false,
            showLoadingPopup: false,
            showRenderPopup: false,
            validPhone: true
        });
    }

    clickHandler(e){
        if(e.target.type==="checkbox") {
            if(!this.props.checked)
                this.setState({showPopupRedirection: true});
            else
                this.deactivate();

        }
    }

    onOffLabel(){
        if(this.props.checked)
            return "włączone";
        return "wyłączone";
    }

    validatePhone(phone){
        if(phone > 500000000 && phone < 900000000){
            this.setState({validPhone: true});
            return true
        }

        this.setState({validPhone: false});
        return false;
    }


    phoneHandler = (event) => {
        const value = parseInt(event.target.value,10);

        if(Number.isInteger(value) && value > 0 )
            this.setState({phone: value});
        else
            this.setState({phone: ''});
    }

    deactivate(){
        this.props.setLoading();

        RestApi.deactivateRedirect(this.props.item.id,(response)=>{
            const data = response.data;
            if(data.status) {
                this.closePopupHandler();
                this.props.loadAccoutData();
            } else {
                this.closePopupHandler();
                this.props.loadAccoutData();
                let x;
                for (x in data.message){
                    alert(data.message[x]);
                }

            }
        }, (error)=>{
            this.setState({
                loading: false,
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        } );
    }

    applayHandler() {
        let result = this.validatePhone(this.state.phone);

        if (!result)
            return 0;

        this.props.setLoading();

        RestApi.redirect(this.state.phone,this.props.item.id,(response)=>{
            const data = response.data;
            if(data.status) {
                this.closePopupHandler();
                this.props.loadAccoutData();
            } else {
                this.closePopupHandler();
                this.props.loadAccoutData();
                let x;
                for (x in data.message){
                    alert(data.message[x]);
                }

            }
        }, (error)=>{
            this.setState({
                loading: false,
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        } );
    }

    renderDisable(){
        if(!this.props.disable)
            return null;

        let popup;

        if(this.state.showRenderPopup)
            popup = <Popup title={this.props.item.name}
                           cancelTitle="zamknij objaśnienie"
                           cancelHandler={this.closePopupHandler.bind(this)}><p>W obecnej konfiguracji usług nie można wykonać operacji na komponencie.</p></Popup>

        return (
            <React.Fragment>
                <div className="checkbox-block__loading" onClick={this.showRenderPopup.bind(this)}/>
                {popup}
            </React.Fragment>
        );
    }

    renderOperationInProgress(){
        if(!this.props.operationInProgress)
            return null;

        let popup;

        if(this.state.showLoadingPopup)
            popup = <Popup title={this.props.item.name}
                           cancelTitle="zamknij objaśnienie"
                           cancelHandler={this.closePopupHandler.bind(this)}><p>Poczekaj na zatwierdzenie zmian i spróbuj ponownie.</p></Popup>

        return (
            <React.Fragment>
                <div className="checkbox-block__loading" onClick={this.showLoadingPopup.bind(this)} />
                {popup}
            </React.Fragment>
        );
    }



    render(){
        let popup;
        let icon;
        let popupRedirect;

        if(this.state.showPopup)
            popup = <Popup title={this.props.item.name}
                           cancelTitle="zamknij objaśnienie"
                           cancelHandler={this.closePopupHandler.bind(this)}><p>{this.props.item.description}</p></Popup>

        if(this.state.showPopupRedirection) {
            const PHONE_CLASS = [];
            PHONE_CLASS.push('input-field');

            if(!this.state.validPhone) {
                PHONE_CLASS.push('input-field--error');
                PHONE_CLASS.push('shake');
                PHONE_CLASS.push('animated');
            }

            popupRedirect = <Popup title="Przekierowanie na numer telefonu"

                                   okTitle="Aktywuj usługę"
                                   okHandler={this.applayHandler.bind(this)}
                                   okClassName="btn--success"

                                   cancelTitle="Rezygnuje"
                                   cancelHandler={this.closePopupHandler.bind(this)}>
                <input type="text" placeholder="podaj numer telefonu" className={PHONE_CLASS.join(' ')}
                       onChange={this.phoneHandler.bind(this)} value={this.state.phone} maxLength="9"/>
            </Popup>
        }


        if(this.props.item.description)
            icon = <button type="button" onClick={this.openInfoPopupHandler.bind(this)}><img src="/img/information.svg" alt="info"/></button>

        let loading = this.renderOperationInProgress();
        let disable = this.renderDisable();

        return (
            <React.Fragment>
                <section className="checkbox-block">
                    <header className="checkbox-block__header">
                        <h2>{this.props.item.name}</h2>
                        <div className="checkbox-block__icon">
                            {icon}
                        </div>
                    </header>
                    <div className="checkbox-block__body">
                        <label className="on-off-button" onClick={this.clickHandler.bind(this)}>
                            <div className="label">{this.props.item.name} - {this.onOffLabel()}</div>
                            <input type="checkbox" checked={this.props.checked} />
                            <div className="on-off-button__bg">
                                <div className="on-off-button__circle"></div>
                            </div>
                        </label>
                    </div>
                    {loading}
                    {disable}
                </section>
                {popup}
                {popupRedirect}
            </React.Fragment>
        );
    }
}

export default App;