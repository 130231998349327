import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Layout from '../components/WelcomeLayout';

class App extends Component {

    render() {


        return (

            <Layout title="Brak strony" subtitle="Strona która próbujesz otworzyć nieistnieje." icon="exclamation">
                <footer>
                    <p><Link to="/" className="link link--olive">Powrót</Link></p>
                </footer>
            </Layout>

        );
    }

}

export default App;
