import React, {Component} from 'react';


class App extends Component {

    renderShieldIcon() {
        return (
            <div><img src="/img/shield.svg" alt="" aria-hidden="true" className="shield-img"/></div>
        );
    }

    renderExclamationIcon() {
        return (
            <div><img src="/img/exclamation-triangle-solid.svg" alt="" aria-hidden="true" className="exclamation-img"/>
            </div>
        );
    }

    renderTimesIcon() {
        return (
            <div><img src="/img/times-circle-solid.svg" alt="" aria-hidden="true" className="times-img"/></div>
        );
    }

    render() {
        const TITLE = this.props.title ? this.props.title : '',
            SUB_TITLE = this.props.subtitle ? this.props.subtitle : '',
            SUB_TITLE2 = this.props.subtitle2 ? this.props.subtitle2 : '';

        let icon = '';

        switch (this.props.icon) {
            case 'shield':
                icon = this.renderShieldIcon();
                break;
            case 'exclamation':
                icon = this.renderExclamationIcon();
                break;
            case 'times':
                icon = this.renderTimesIcon();
                break;
            default:
                icon = '';
        }

        return (
            <section className="screen screen--welcome screen--center">

                <header>
                    <div className="app-logo">
                        <img src="/img/logo.svg" alt="Forweb - logo"/>
                    </div>

                    {icon}

                    <h1>{TITLE}</h1>
                    <p>{SUB_TITLE}</p>
                    {SUB_TITLE2 && (<p>
                        {SUB_TITLE2}
                    </p>)}

                </header>

                {this.props.children}

                <footer>
                </footer>

            </section>
        );
    }
}

export default App;