const defaultState = {
    auth: {
        token_id: null,
        phone: null
    }
};

function authorization(token_id,phone,state) {
    const newState = Object.assign({}, state);

    newState.auth.token_id = token_id;
    newState.auth.phone = phone;

    return newState;
}

function unauthorization(state) {
    const newState = Object.assign({}, state);

    newState.auth = {};

    return defaultState;
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'AUTHORIZATION':
            return authorization(action.token_id,action.phone, state);
        case 'UNAUTHORIZATION':
            return unauthorization(state);
        default:
            return state
    }
}