import React, { Component } from 'react';
import Ban1 from './ban/Ban1';
import Ban2 from './ban/Ban2';
import BanSuccess from './ban/BanSuccess';

class App extends Component {

    constructor(props){
        super(props);

        this.state = {
            step1: true,
            step2: false,
            phone: null
        };
    }

    setPhone(phone){
        this.setState({
            step1: false,
            step2: true,
            phone: phone
        });
    }

    gotToStep1(){
        this.setState({
            step1: true,
            step2: false,
            phone: null
        });
    }

    goToSuccess(){console.log('sukces')
        this.setState({
            step1: false,
            step2: false,
            phone: null
        });
    }

    render() {
        let content;

        if(this.state.step1)
            content = <Ban1 setPhone={this.setPhone.bind(this)}/>;
        else if(this.state.step2)
            content = <Ban2 phone={this.state.phone} gotToStep1={this.gotToStep1.bind(this)} goToSuccess={this.goToSuccess.bind(this)}/>;
        else
            content = <BanSuccess />;

        return (
            <React.Fragment>
                {content}
            </React.Fragment>
        );
    }

}

export default App;
