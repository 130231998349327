import {store} from './../store';

const axios = require('axios');
const qs = require('querystring');

export default new function () {
    const ADDRESS = process.env.REACT_APP_API_URL;

    return {
        accountData: (callback, errorCallback, data) => {
            const phone = data && data.phone ? data.phone : store.getState().auth.phone,
                token_id = data && data.token_id ? data.token_id : store.getState().auth.token_id;
            axios.get(ADDRESS + 'account-data?phone=' + phone + '&token=' + token_id, {withCredentials: true}).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        activateAccount: (phone, code, callback, errorCallback) => {
            axios.post(ADDRESS + 'activate-account', qs.stringify({
                phone: phone,
                code: code
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true
            }).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        banAccount: (phone, code, callback, errorCallback) => {
            axios.post(ADDRESS + 'ban-account', qs.stringify({
                phone: phone,
                code: code
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true
            }).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        buyPackage: (id, callback, errorCallback) => {
            const phone = store.getState().auth.phone,
                token = store.getState().auth.token_id;

            axios.post(ADDRESS + 'buy-package', qs.stringify({
                phone: phone,
                token: token,
                package_id: id
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true
            }).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        deactivateRedirect: (service_id, callback, errorCallback) => {
            const phone = store.getState().auth.phone,
                token = store.getState().auth.token_id;

            axios.post(ADDRESS + 'deactivate-redirect-to-phone', qs.stringify({
                phone: phone,
                token: token,
                service_id: service_id
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true
            }).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        disablePacket: (id, callback, errorCallback) => {
            const phone = store.getState().auth.phone,
                token = store.getState().auth.token_id;

            axios.post(ADDRESS + 'disable-package', qs.stringify({
                phone: phone,
                token: token,
                service: id
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true
            }).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        emailCode: (phone, callback, errorCallback) => {
            axios.get(ADDRESS + 'email-code?phone=' + phone, {
                withCredentials: true
            }).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        enablePacket: (id, callback, errorCallback) => {
            const phone = store.getState().auth.phone,
                token = store.getState().auth.token_id;

            axios.post(ADDRESS + 'enable-package', qs.stringify({
                phone: phone,
                token: token,
                service: id
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true
            }).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        validatePhone: (phone, callback, errorCallback) => {
            axios.get(ADDRESS + 'validate-phone-number?phone=' + phone, {withCredentials: true}).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        isPhoneAvailable: (phone, callback, errorCallback) => {
            axios.get(ADDRESS + 'is-phone-available?phone=' + phone, {withCredentials: true}).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        login: (phone, code, callback, errorCallback) => {
            axios.post(ADDRESS + 'login', qs.stringify({
                phone: phone,
                code: code
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true
            }).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        logout: (callback, errorCallback) => {
            axios.post(ADDRESS + 'logout', null, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true
            }).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        operations: (data, callback, errorCallback) => {
            const phone = store.getState().auth.phone,
                token = store.getState().auth.token_id;

            axios.post(ADDRESS + 'operations', qs.stringify({
                phone: phone,
                token: token,
                operations: JSON.stringify(data)
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true
            }).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        redirect: (redirect, service_id, callback, errorCallback) => {
            const phone = store.getState().auth.phone,
                token = store.getState().auth.token_id;

            axios.post(ADDRESS + 'redirect-to-phone', qs.stringify({
                phone: phone,
                token: token,
                redirect: redirect,
                service_id: service_id
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true
            }).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        sendCode: (phone, time, callback, errorCallback) => {
            axios.get(ADDRESS + 'send-code?time=' + time + '&timezone=' + new Date().getTimezoneOffset()+ '&phone=' + phone, {withCredentials: true}).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        simInfo: (callback, errorCallback) => {
            const token = store.getState().auth.token_id;
            axios.get(ADDRESS + 'get-sim-info?token=' + token, {withCredentials: true}).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        },
        voicemail: (callback, errorCallback) => {
            const phone = store.getState().auth.phone,
                token = store.getState().auth.token_id;

            axios.post(ADDRESS + 'voicemail', qs.stringify({
                phone: phone,
                token: token
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                withCredentials: true
            }).then((response) => {
                callback(response);
            }).catch((error) => {
                errorCallback(error);
            });
        }
    }
}