import React, { Component } from 'react';
import Popup from './Popup';
import RestApi from "../helper/RestActions";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
            showLoadingPopup: false,
            showRenderPopup: false,
        };
    }

    openInfoPopupHandler(){
        this.setState({showPopup: true});
    }

    showLoadingPopup(){
        this.setState({showLoadingPopup: true});
    }
    showRenderPopup(){
        this.setState({showRenderPopup: true});
    }

    closePopupHandler(){
        this.setState({
            showPopup: false,
            showLoadingPopup: false,
            showRenderPopup: false
        });
    }

    clickHandler(e){

        if(e.target.type==="checkbox") {

            if (!this.props.checked)
                this.voicemail();
            else
                this.deactivate();
        }
    }



    deactivate(){
        this.props.setLoading();

        RestApi.deactivateRedirect(this.props.item.id,(response)=>{
            const data = response.data;
            if(data.status) {
                this.closePopupHandler();
                this.props.loadAccoutData();
            } else {
                this.closePopupHandler();
                this.props.loadAccoutData();
                let x;
                for (x in data.message){
                    alert(data.message[x]);
                }

            }
        }, (error)=>{
            this.setState({
                loading: false,
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        } );
    }

    voicemail(){
        this.props.setLoading();

        RestApi.voicemail((response)=>{
            const data = response.data;
            if(data.status) {
                this.props.loadAccoutData();
            } else {
                this.setState({
                    loading: false,
                    'error': {
                        'title': data.message,
                        'subtitle': ""
                    }
                },()=>{
                    setTimeout(()=>global.localStorage.clear(),2000)
                });
            }
        }, (error)=>{
            this.setState({
                loading: false,
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        } );
    }

    name(){
        let name = this.props.item.name.replace('Przekierowanie - ','');
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    renderOperationInProgress(){
        if(!this.props.operationInProgress)
            return null;

        let popup;

        if(this.state.showLoadingPopup)
            popup = <Popup title={this.props.item.name}
                           cancelTitle="zamknij objaśnienie"
                           cancelHandler={this.closePopupHandler.bind(this)}><p>Poczekaj na zatwierdzenie zmian i spróbuj ponownie.</p></Popup>

        return (
            <React.Fragment>
                <div className="checkbox-block__loading" onClick={this.showLoadingPopup.bind(this)} />
                {popup}
            </React.Fragment>
        );
    }

    renderDisable(){
        if(!this.props.disable)
            return null;

        let popup;

        if(this.state.showRenderPopup)
            popup = <Popup title={this.props.item.name}
                           cancelTitle="zamknij objaśnienie"
                           cancelHandler={this.closePopupHandler.bind(this)}><p>W obecnej konfiguracji usług nie można wykonać operacji na komponencie.</p></Popup>

        return (
            <React.Fragment>
                <div className="checkbox-block__loading" onClick={this.showRenderPopup.bind(this)}/>
                {popup}
            </React.Fragment>
        );
    }

    onOffLabel(){
        if(this.props.checked)
            return "włączone";
        return "wyłączone";
    }

    render(){
        let popup;
        let popupRedirect;
        let icon;

        if(this.state.showPopup)
            popup = <Popup title={this.name()}
                           cancelTitle="zamknij objaśnienie"
                           cancelHandler={this.closePopupHandler.bind(this)}><p>{this.props.item.description}</p></Popup>

        if(this.props.item.description)
            icon = <button type="button" onClick={this.openInfoPopupHandler.bind(this)}><img src="/img/information.svg" alt="info"/></button>


        let loading = this.renderOperationInProgress();
        let disable = this.renderDisable();

        return (
            <React.Fragment>
                <section className="checkbox-block">
                    <header className="checkbox-block__header">
                        <h2>{this.name()}</h2>
                        <div className="checkbox-block__icon">
                            {icon}
                        </div>
                    </header>
                    <div className="checkbox-block__body">
                        <label className="on-off-button" onClick={this.clickHandler.bind(this)}>
                            <div className="label">{this.name()} - {this.onOffLabel()}</div>
                            <input type="checkbox" checked={this.props.checked} />
                            <div className="on-off-button__bg">
                                <div className="on-off-button__circle"></div>
                            </div>
                        </label>
                    </div>
                    {loading}
                    {disable}
                </section>
                {popup}
            </React.Fragment>
        );
    }
}

export default App;