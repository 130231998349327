import React, { Component } from 'react';
import Login1 from './login/Login1';
import Login2 from './login/Login2';

class App extends Component {

    constructor(props){
        super(props);

        this.state = {
            step1: true,
            phone: null,
            animation: false
        };
    }

    setPhone(phone){

        this.setState({
            step1: false,
            phone: phone
        });
        // this.setState({
        //     animation: true,
        //     phone: phone
        // },()=>{
        //     setTimeout(()=>{
        //         this.setState({
        //             step1: false,
        //             animation: false
        //         });
        //     },700)
        // });
    }

    gotToStep1(){
        this.setState({
            step1: true,
            phone: null
        });
    }

    render() {

        const CONTENT = this.state.step1?<Login1 setPhone={this.setPhone.bind(this)}/>:<Login2 phone={this.state.phone} gotToStep1={this.gotToStep1.bind(this)} />;

        const ANIMATION_CLASS = '';//this.state.animation?'flipOutY':'';

        return (
            <React.Fragment>
                <div className={ANIMATION_CLASS}>
                {CONTENT}
                </div>
            </React.Fragment>
        );
    }

}

export default App;
