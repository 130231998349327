import React, {Component} from 'react';
import SmallCard from './../../components/SmallCard';
import Internet from './../../components/Internet';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    getInternetData() {
        return this.props.internet.used + ' ' + this.props.internet.used_unit;
    }

    render() {
        const CARDS = [],
            INTERNET = [];

        // if (this.props.calls) {
        //     CARDS.push(<SmallCard title="Rozmowy" body={this.props.calls} key="card1"/>);
        // } else {
        //     CARDS.push(<SmallCard title="Rozmowy" body="0" key="card1"/>);
        // }
        // if (this.props.messages) {
        //     CARDS.push(<SmallCard title="SMS-y i MMS-y" body={this.props.messages} key="card2"/>);
        // } else {
        //     CARDS.push(<SmallCard title="SMS-y i MMS-y" body="0" key="card2"/>);
        // }
        CARDS.push(<SmallCard title="Rozmowy" body={this.props.calls ? this.props.calls : '0'} key="card1"/>);
        CARDS.push(<SmallCard title="SMS-y i MMS-y" body={this.props.messages ? this.props.messages : '0'} key="card2"/>);

        INTERNET.push(<Internet internet={this.props.internet}
                                available_packets={this.props.available_packets}
                                setLoading={this.props.setLoading}
                                loadAccoutData={this.props.loadAccoutData}
                                key="card3"/>);

        return (
            <React.Fragment>
                <h1 className="card--size-1-of-1">Podsumowanie usług podstawowych</h1>

                {INTERNET}
                <div className="cards">
                    {CARDS}
                </div>


            </React.Fragment>
        );
    }
}

export default App;