import React, { Component } from 'react';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            progress: 0
        };
    }


    render() {

        const BUTTONS = [];

        if(this.props.okHandler && this.props.okTitle){
            const OKCSS =['btn'];
            if(this.props.okClassName)
                OKCSS.push(this.props.okClassName);

            BUTTONS.push(
                <button key="btn1" type="button" className={OKCSS.join(' ')} onClick={this.props.okHandler}>{this.props.okTitle}</button>
            );
        }

        if(this.props.cancelHandler && this.props.cancelTitle)
            BUTTONS.push(
                <button key="btn2" type="button" className="popup__closeBtn" onClick={this.props.cancelHandler}>{this.props.cancelTitle}</button>
            );

        return (
            <div className="popup">
                <div className="popup__wrapper">
                    <div className="popup__header">{this.props.title}</div>
                    <div className="popup__body">{this.props.children}</div>
                    <div className="popup__footer">
                        {BUTTONS}
                    </div>
                </div>
            </div>
        );
    }
}

export default App;