import React, { Component } from 'react';
import Popup from './Popup';
import RestApi from "../helper/RestActions";

class App extends Component {

    constructor(props) {
        super(props);

        let phone = "";
        if(this.props.user_data && this.props.user_data.redirect)
            phone = this.props.user_data.redirect;

        this.state = {
            showPopup: false,
            showPopupRedirection: false,
            validPhone: true,
            phone: phone
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if(!this.state.validPhone) {
            setTimeout(() => this.setState({validPhone: true}), 1500);
        }
    }

    openInfoPopupHandler(){
        this.setState({showPopup: true});
    }

    closePopupHandler(){
        this.setState({showPopup: false,showPopupRedirection: false,validPhone: true});
    }

    clickHandler(e){

        if(e.target.type==="checkbox") {
            // alert('W DEV')

            if (this.props.item.id == 27) //na numer tel
                this.setState({showPopupRedirection: true});
            else if (this.props.item.id == 34) //poczta glosowa
            {
                this.voicemail();
            }

            //
            // this.props.click(this.props.item.id);
        }
    }

    voicemail(){
        this.props.setLoading();

        RestApi.voicemail((response)=>{
            const data = response.data;
            if(data.status) {
                this.props.loadAccoutData();
            } else {
                this.setState({
                    loading: false,
                    'error': {
                        'title': data.message,
                        'subtitle': ""
                    }
                },()=>{
                    setTimeout(()=>global.localStorage.clear(),2000)
                });
            }
        }, (error)=>{
            this.setState({
                loading: false,
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        } );
    }

    applayHandler(){
        let result = this.validatePhone(this.state.phone);

        if(!result)
            return 0;

        this.props.setLoading();

        RestApi.redirect(this.state.phone,this.props.item.id,(response)=>{
            const data = response.data;
            if(data.status) {
                this.closePopupHandler();
                this.props.loadAccoutData();
            } else {
                this.closePopupHandler();
                this.props.loadAccoutData();
                let x;
                for (x in data.message){
                    alert(data.message[x]);
                }

            }
        }, (error)=>{
            this.setState({
                loading: false,
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        } );

    }

    validatePhone(phone){
        if(phone > 500000000 && phone < 900000000){
            this.setState({validPhone: true});
            return true
        }

        this.setState({validPhone: false});
        return false;
    }


    phoneHandler = (event) => {
        const value = parseInt(event.target.value,10);

        if(Number.isInteger(value) && value > 0 )
            this.setState({phone: value});
        else
            this.setState({phone: ''});
    }

    name(){
        let name = this.props.item.name.replace('Przekierowanie - ','');
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    render(){
        let popup;
        let popupRedirect;
        let icon;

        if(this.state.showPopup)
            popup = <Popup title={this.name()}
                           cancelTitle="zamknij objaśnienie"
                           cancelHandler={this.closePopupHandler.bind(this)}><p>{this.props.item.description}</p></Popup>
        if(this.state.showPopupRedirection) {
            const PHONE_CLASS = [];
            PHONE_CLASS.push('input-field');

            if(!this.state.validPhone) {
                PHONE_CLASS.push('input-field--error');
                PHONE_CLASS.push('shake');
                PHONE_CLASS.push('animated');
            }

            popupRedirect = <Popup title="Przekierowanie na numer telefonu"

                           okTitle="Aktywuj usługę"
                           okHandler={this.applayHandler.bind(this)}
                           okClassName="btn--success"

                           cancelTitle="Rezygnuje"
                           cancelHandler={this.closePopupHandler.bind(this)}>
                <input type="text" placeholder="podaj numer telefonu" className={PHONE_CLASS.join(' ')}
                       onChange={this.phoneHandler.bind(this)} value={this.state.phone} maxLength="9"/>
            </Popup>
        }


        if(this.props.item.description)
            icon = <button type="button" onClick={this.openInfoPopupHandler.bind(this)}><img src="/img/information.svg" alt="info"/></button>



        return (
            <React.Fragment>
                <section className="radiobox-block">
                    <div className="radiobox-block__body">
                        <label className="radiobox-block__label" onClick={this.clickHandler.bind(this)}>
                            <input type="checkbox" checked={this.props.checked} />
                            <div className="radiobox-block__circle1">
                                <div className="radiobox-block__circle2"></div>
                            </div>

                            <div className="radiobox-block__name">{this.name()}</div>
                        </label>
                        <div className="radiobox-block__icon">
                            {icon}
                        </div>
                    </div>
                </section>
                {popup}
                {popupRedirect}
            </React.Fragment>
        );
    }
}

export default App;