import React, { Component } from 'react';
import QRCode from "react-qr-code";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	render() {
		const simInfo = JSON.parse(global.localStorage.getItem('simInfo'));
		if (!simInfo['lpa']) {
			this.props.back();
		}
		
		return (
			<React.Fragment>
				<h1 className="card--size-1-of-1">Zeskanuj poniższy kod QR aby zainstalować kartę e-sim</h1>
				<QRCode
					size={256}
					style={{height: "auto", maxWidth: "100%", width: "100%", marginBottom: "20px"}}
					value={simInfo['lpa']}
					viewBox={`0 0 256 256`}
				/>
			</React.Fragment>
		);
	}
}


export default App;