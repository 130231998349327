import React, {Component} from 'react';
import Popup from './Popup';
import PopupPacket from './PopupBuyPackets';
import RestApi from './../helper/RestActions';
import { Scrollbar } from 'react-scrollbars-custom';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            progress: 0,
            showPacketPopup: false,
            selectedPacket: null
        };
    }

    getInternetData() {
        return this.props.internet.used + ' ' + this.props.internet.unit;
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.internet != nextProps.internet && nextProps.internet && nextProps.internet.used && nextProps.internet.total) {
            // if(!this.props.internet)
            console.log('componentWillReceiveProps', this.props.internet)
            let value = (nextProps.internet.used / nextProps.internet.total) * 100;
            if (value > 100)
                value = 100;

            setTimeout(() => {
                this.setState({progress: value});
            }, 500);

        }
    }

    componentDidMount() {

        if (this.props.internet && this.props.internet.used && this.props.internet.total) {

            let value = (this.props.internet.used / this.props.internet.total) * 100;
            if (value > 100)
                value = 100;

            setTimeout(() => {
                this.setState({progress: value});
            }, 500);
        }
    }

    availablePackets() {
        const packets = this.props.available_packets;
        const output = [];

        if (!packets)
            return output;
        for (let i = 0; i < packets.length; i++) {
            if (packets[i].category === "data") {
                const regex = /([0-9]{1,3}GB)/gmi;
                const m = regex.exec(packets[i].name);
                if (m) {
                    output.push({
                        id: packets[i].id,
                        name: packets[i].name,
                        price: packets[i].price,
                        size: m[0]
                    });
                }
            }
        }

        return output;
    }

    choosePacketHandler(item) {
        this.setState({showPacketPopup: true, selectedPacket: item});
    }

    closePacketHandler() {
        this.setState({showPacketPopup: false, selectedPacket: null});
    }

    applayPacketHandler() {
        this.props.setLoading();

        RestApi.buyPackage(this.state.selectedPacket.id, (response) => {
            const data = response.data;

            if (data.status) {
                this.closePacketHandler();
                this.props.loadAccoutData();
            } else {
                this.setState({
                    loading: false,
                    'error': {
                        'title': "Ups! Coś poszło nie tak",
                        'subtitle': data.message,
                    }
                }, () => {
                    setTimeout(() => global.localStorage.clear(), 2000)
                });
            }
        }, (error) => {
            this.setState({
                loading: false,
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        });

    }

    purchasedPackagesRender() {
        return (
            <React.Fragment key="sds">
                <p>Wykupiłeś dodatkowy pakiet danych<br/>
                    <div className="buy-packets__value"><b>{this.props.internet.purchased.total}GB</b></div>
                </p>
            </React.Fragment>
        );
    }

    renderProggress() {

        const progress = {
            width: this.state.progress + "%"
        };

        if (this.props.internet.unit === null && this.props.internet.used === null)
            return false;

        return (
            <div className="progress-bar-field">
                <h3 className="progress-bar-field__title">Wykorzystanie danych</h3>
                <div className="progress-bar-field__bg">
                    <div className="progress-bar-field__bar" style={progress}></div>
                </div>
                <div className="progress-bar-field__value"><b>{this.props.internet.used}{this.props.internet.used_unit}</b> / {this.props.internet.total}{this.props.internet.unit}</div>
                <div className="progress-bar-field__info">Pakiet zostanie odnowiony
                    za {this.props.internet.end} dni
                </div>
            </div>
        );
    }


    render() {


        const PACKETS = [],
            PURCHASEDPACKETS = [],
            POPUPS = [];
        this.availablePackets().forEach((item, index) => {
            PACKETS.push((
                <button type="button" key={index} onClick={this.choosePacketHandler.bind(this, item)}>Dokup pakiet {item.size} za {item.price} zł</button>
            ))
        });
        if (this.props.internet && this.props.internet.purchased.total) {
            PURCHASEDPACKETS.push(this.purchasedPackagesRender());

        }
        if (this.state.selectedPacket && this.state.showPacketPopup)
            POPUPS.push(<PopupPacket title="Potwierdź zakup"
                                     okTitle="Potwierdzam zakup"
                                     okHandler={this.applayPacketHandler.bind(this)}
                                     okClassName="btn--success"
                                     cancelTitle="Rezygnuję"
                                     cancelHandler={this.closePacketHandler.bind(this)} key="aaa"><p>{this.state.selectedPacket.size} pakietu
                danych <strong>{this.state.selectedPacket.price} zł</strong></p></PopupPacket>);

        let progress;
        if (this.props.internet) {
            progress = this.renderProggress();
        }

        return (
            <section className="card--size-1-of-1 card packages-card">
                <header><h2>Internet</h2></header>
                <div className="card__body">
                    {progress}

                    {/*<div className="buy-packets">*/}
                    {/*    {PACKETS}*/}
                    {/*</div>*/}

                    {PACKETS.length > 0 ?
                        <Scrollbar className="buy-packets" style={{width: '100%', height: '94px'}} noScrollY={true} noScrollX={false}>
                            {PACKETS}
                        </Scrollbar>
                        : <div>
                            Brak możliwych pakietów do wykupienia
                        </div>
                    }

                </div>
                <div>
                    {PURCHASEDPACKETS}
                </div>
                {POPUPS}
            </section>
        );
    }
}

export default App;