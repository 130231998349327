import React, {Component} from 'react';
import Checkbox from "../../components/Checkbox";
import RestApi from "../../helper/RestActions";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }
    isPremiumPacketActive(id) {
        if (!this.props.active_packets)
            return false;

        if (this.props.active_packets.indexOf(id) === -1)
            return false;
        return true;
    }
    enablePacket(id) {
        this.props.setLoading();

        RestApi.enablePacket(id, (response) => {
            const data = response.data;
            if (data.status) {
                this.props.loadAccoutData();
            } else {
                this.setState({
                    loading: false,
                    'error': {
                        'title': "Ups! Coś poszło nie tak",
                        'subtitle': data.message,
                    }
                }, () => {
                    setTimeout(() => global.localStorage.clear(), 2000)
                });
            }
        }, (error) => {
            this.setState({
                loading: false,
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        });
    }
    disablePacket(id) {
        this.props.setLoading();

        RestApi.disablePacket(id, (response) => {
            const data = response.data;
            if (data.status) {
                this.props.loadAccoutData();
            } else {
                this.setState({
                    loading: false,
                    'error': {
                        'title': data.message,
                        'subtitle': ""
                    }
                }, () => {
                    setTimeout(() => global.localStorage.clear(), 2000)
                });
            }
        }, (error) => {
            this.setState({
                loading: false,
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        });
    }

    render() {
        const PACKETS = [];

        if (this.props.premium_services) {
            // const active_packets = [];
            this.props.premium_services.forEach((item, index) => {
                let checked = this.isPremiumPacketActive(item.id);
                let action = checked ? this.disablePacket.bind(this) : this.enablePacket.bind(this);

                let operationInProgress = false;
                if (this.props.operations.indexOf(item.id) !== -1)
                    operationInProgress = true;

                const regex = /^(Przekierowanie -)/gmi;
                const m = regex.exec(item.name);
                if (!m && item.id != "34") // nie poczta glosowa
                    PACKETS.push(<Checkbox item={item} checked={checked} click={action} key={index}
                                           operationInProgress={operationInProgress}/>)
            });
            // if(active_packets.length)
            //     CARDS.push(<Card title="Aktywne pakiety" body={active_packets} />);
        }

        return (
            <React.Fragment>
                <h1 className="card--size-1-of-1">Ustawienia podstawowe</h1>
                {PACKETS}
            </React.Fragment>
        );
    }
}


export default App;