import React, {Component} from 'react';
import {connect} from "react-redux";
import RestApi from './../helper/RestActions';
import Error from './Error';
import DefaultSettings from './account/DefaultSettings';
import Settings from './account/Settings';
import Help from './account/Help';
import Redirections from './account/Redirections';
import ActivePackages from './account/ActivePackages';
import ChangePhone from './account/ChangePhone';
import Push from '../helper/PushNotification'
import {unauthorization} from '../store/actions';
import addNotification, {Notifications} from "react-push-notification";
import ESim from "./account/ESim";

let intervalID;

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            active_packets: null,
            available_packets: null,
            calls: null,
            error: null,
            firm_email: null,
            firm_name: null,
            internet: null,
            loading: true,
            messages: null,
            mobiles: JSON.parse(global.localStorage.getItem('mobiles')),
            operations: null,
            premium_services: null,
            purchased_packages: null,
            partner_data: null,
            screen: 'DefaultSettings',
            simInfo: {},
            user_data: null,
            // push: Push()
        };
    }

    componentWillMount() {
        // Push(this.props.pwaToken);
        Push(global.localStorage.getItem('token_id'));
        this.loadAccoutData();
    }

    loadOperations() {

        if (!global.localStorage.getItem('token_id'))
            // if (!this.props.pwaToken)
            return false;

        RestApi.operations(this.state.operations, (response) => {
            const data = response.data;
            if ((typeof data == "object" && data.length == 0) || data) {
                if (this.state.operations.length != data.length) {
                    this.loadAccoutData();
                } else {
                    this.setState({
                        operations: data,
                    });
                }
            } else {
                clearInterval(intervalID);
                this.setState({
                    loading: false,
                    'error': {
                        'title': data.title ? data.title : "Ups! Coś poszło nie tak",
                        'subtitle': data.message,
                    }
                }, () => {
                    setTimeout(() => global.localStorage.clear(), 200)
                });
            }
        }, (error) => {
            this.setState({
                loading: false,
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        });
    }

    loadSimInfo() {

        if (!global.localStorage.getItem('token_id'))
            // if (!this.props.pwaToken)
            return false;

        RestApi.simInfo((response) => {
            const data = response.data;
            if (data.status) {
                global.localStorage.setItem('simInfo', JSON.stringify(data.message));
                this.setState({
                    simInfo: JSON.stringify(data.message)
                });
            } else {
                clearInterval(intervalID);
                this.setState({
                    loading: false,
                    'error': {
                        'title': data.title ? data.title : "Ups! Coś poszło nie tak",
                        'subtitle': data.message,
                    }
                }, () => {
                    setTimeout(() => global.localStorage.clear(), 200)
                });
            }
        }, (error) => {
            this.setState({
                loading: false,
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        });
    }

    loadAccoutData(data) {
        if (!global.localStorage.getItem('token_id') && !this.props.pwaToken)
            // if (!this.props.pwaToken)
            return false;

        RestApi.accountData((response) => {
            this.loadSimInfo();
            const data = response.data;
            if (this.state.operations) {
                data.premium_services.map((service) => {
                    this.state.operations.map((operation) => {
                        if (!data.operations.includes(operation) && operation == service.id) {
                            addNotification({
                                title: 'Zmiana stanu pakietów',
                                message: 'Usługa "' + service.name + '" została ' + (data.active_packets.includes(service.id) ? ' aktywowana' : ' deaktywowana'),
                                theme: 'darkblue',
                                native: true // when using native, your OS will handle theming.
                            });
                        }
                    });
                });
            }
            if (data.status) {
                this.setState({
                    messages: data.messages,
                    calls: data.calls,
                    firm_email: data.partner_data.email,
                    firm_name: data.partner_data.name,
                    internet: data.internet,
                    active_packets: data.active_packets,
                    premium_services: data.premium_services,
                    available_packets: data.available_packets,
                    purchased_packages: data.purchased_packages,
                    partner_data: data.partner_data,
                    user_data: data.user_data,
                    operations: data.operations,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false,
                    'error': {
                        'title': data.title ? data.title : "Ups! Coś poszło nie tak",
                        'subtitle': data.message,
                    }
                }, () => {
                    clearInterval(intervalID);
                    this.props.unauthorization();
                    setTimeout(() => global.localStorage.clear(), 200)
                });
            }
        }, (error) => {
            this.setState({
                loading: false,
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            }, () => this.props.unauthorization());
        }, data);
    }

    componentDidMount() {
        global.window.scrollTo(0, 0);

        if (intervalID)
            clearInterval(intervalID);
        // intervalID = setInterval(() => this.loadOperations(), 5000);
        intervalID = setInterval(() => this.loadOperations(), 30000);
    }

    getPacketName(id) {
        if (!this.state.available_packets)
            return false;

        let result = false;

        this.state.available_packets.forEach((x) => {
            if (x.id === id)
                result = x.name;
        });

        return result;
    }


    setLoading() {
        this.setState({
                loading: true
            }
        );
    }

    showScreen(screen) {
        window.scrollTo(0, 0);
        this.setState({screen: screen});
    }

    renderLoading() {
        return (
            <div className="loading">
                <div className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }

    renderNavigation() {
        const simInfo = JSON.parse(global.localStorage.getItem('simInfo'));
        if (this.state.screen === "DefaultSettings")
            return (
                <React.Fragment>
                    <button onClick={this.showScreen.bind(this, 'ActivePackages')} type="button"
                            className="menu-card menu-card--size-1-of-2 menu-card--left">Aktywne pakiety dodatkowe
                    </button>
                    <button onClick={this.showScreen.bind(this, 'Settings')} type="button"
                            className="menu-card menu-card--size-1-of-2 menu-card--right">Ustawienia podstawowe
                    </button>
                    <button onClick={this.showScreen.bind(this, 'Redirections')} type="button"
                            className="menu-card menu-card--size-1-of-2 menu-card--left">Ustawienia przekierowań
                    </button>
                    <button onClick={this.showScreen.bind(this, 'Help')} type="button"
                            className="menu-card menu-card--size-1-of-2 menu-card--right">Pomoc techniczna
                    </button>
                    {simInfo && simInfo['lpa'] ?
                        <button onClick={this.showScreen.bind(this, 'ESim')} type="button"
                                className="menu-card menu-card--size-1-of-1 menu-card--back">E-Sim
                        </button>
                        : ''}
                </React.Fragment>
            )
        return (
            <button onClick={this.showScreen.bind(this, 'DefaultSettings')} type="button"
                    className="menu-card menu-card--size-1-of-1 menu-card--back">powrót</button>
        )
    }

    render() {

        if (this.state.error)
            return (
                <Error title={this.state.error.title} subtitle={this.state.error.subtitle}/>
            );

        let loading = '';
        let articleClass = "";
        if (this.state.loading) {
            loading = this.renderLoading();
            articleClass = "is-loading";
        }

        // const PHONE = this.phoneNice(this.props.phone.toString());


        // if(this.state.premium_services){
        //     // const active_packets = [];
        //     this.state.premium_services.forEach((item)=>{
        //         let checked = this.isPremiumPacketActive(item.id);
        //         let action = checked?this.disablePacket.bind(this):this.enablePacket.bind(this);
        //
        //         CARDS.push(<Checkbox title={item.name} body={item.description} checked={checked} id={item.id} click={action} />)
        //     });
        //     // if(active_packets.length)
        //     //     CARDS.push(<Card title="Aktywne pakiety" body={active_packets} />);
        // }

        const navigation = this.renderNavigation();

        let screen;

        switch (this.state.screen) {
            case "ActivePackages":
                screen = <ActivePackages
                    internet={this.state.internet}
                    purchased_packages={this.state.purchased_packages}
                />;
                break;
            case "ChangePhone":
                screen = <ChangePhone
                    back={this.showScreen.bind(this, 'DefaultSettings')}
                    loadAccoutData={this.loadAccoutData.bind(this)}
                    phone={this.props.phone}
                    selectedPhone={global.localStorage.getItem('phone')}
                />;
                break;
            case "ESim":
                screen = <ESim
                    back={this.showScreen.bind(this, 'DefaultSettings')}
                />;
                break;
            case "Help":
                screen = <Help
                    partner_data={this.state.partner_data}
                />;
                break;
            case "Settings":
                screen = <Settings
                    active_packets={this.state.active_packets}
                    loadAccoutData={this.loadAccoutData.bind(this)}
                    operations={this.state.operations}
                    premium_services={this.state.premium_services}
                    setLoading={this.setLoading.bind(this)}
                />;
                break;
            case "Redirections":
                screen = <Redirections
                    active_packets={this.state.active_packets}
                    loadAccoutData={this.loadAccoutData.bind(this)}
                    operations={this.state.operations}
                    premium_services={this.state.premium_services}
                    setLoading={this.setLoading.bind(this)}
                    user_data={this.state.user_data}
                />;
                break;
            default:
                screen = <DefaultSettings
                    available_packets={this.state.available_packets}
                    calls={this.state.calls}
                    internet={this.state.internet}
                    loadAccoutData={this.loadAccoutData.bind(this)}
                    messages={this.state.messages}
                    setLoading={this.setLoading.bind(this)}
                />;
        }

        return (

            <section className="screen screen--logged">
                <Notifications/>
                <header>
                    <div className="top">
                        <div className="content">
                            <div className="left">{this.state.firm_name}</div>
                            {/*<div className="right">{this.state.firm_email}</div>*/}
                        </div>
                    </div>

                    <div className="user-info">
                        <ChangePhone inMyAccount={true} showScreen={this.showScreen.bind(this, 'ChangePhone')}
                                     selectedPhone={global.localStorage.getItem('phone')}
                                     phone={this.props.phone} setLoading={this.setLoading.bind(this)}
                                     loadAccoutData={this.loadAccoutData.bind(this)}/>
                        {/*<div className="user-info__item">Wykupiony pakiet: <b>Junior Pro</b></div>*/}
                        {/*<div className="user-info__item">Data końca umowy: <b>21 kwietnia 2019</b></div>*/}
                    </div>
                </header>
                {loading}
                <article className={articleClass}>
                    {screen}
                    {navigation}
                </article>
                <footer>
                    <div className="logout-action">
                        <a href="/wyloguj" className="link link--olive">Wyloguj z aplikacji</a>
                    </div>
                    <div className="left">Powered by Metroport</div>
                    <div className="right"><img src="/img/metroport.svg" alt="" aria-hidden="true"/></div>
                </footer>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        phone: state.auth.phone,
        pwaToken: state.auth.token_id
    }
};

const mapDispatchToProps = dispatch => {
    return {
        unauthorization: () => unauthorization(dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
