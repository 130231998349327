import React, {Component} from 'react';
import axios from "axios";
import qs from "querystring";

class App extends Component {

    constructor(props) {
        super(props);
    }

    changeMaster(item) {
        axios.post(process.env.REACT_APP_API_URL + 'change-master', qs.stringify({
            id: item.id,
            master: !item.master
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            withCredentials: true
        }).then((response) => {
            let mobiles = JSON.parse(global.localStorage.getItem('mobiles')),
                canDisable = false;
            mobiles.forEach((mobile, index) => {
                if (mobile.master && item.id != mobile.id) {
                    canDisable = true;
                }
            });
            mobiles.forEach((mobile) => {
                if (item.id == mobile.id && canDisable) {
                    mobile.master = !mobile.master;
                }
            });
            global.localStorage.setItem('mobiles', JSON.stringify(mobiles));
        });
    }

    changeSelected(item) {
        let mobiles = JSON.parse(global.localStorage.getItem('mobiles')),
            phone = global.localStorage.getItem('phone');
        mobiles.forEach((mobile) => {
            mobile.selected = false;
            if (item.id == mobile.id) {
                mobile.selected = true;
                phone = mobile.phoneNumber;
            }
        });
        global.localStorage.setItem('mobiles', JSON.stringify(mobiles));
        global.localStorage.setItem('phone', phone);
        this.props.setLoading(true);
        axios.post(process.env.REACT_APP_API_URL + 'change-selected', qs.stringify({
            id: item.id,
            selected: !item.selected
        }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            withCredentials: true
        }).then((response) => {
            this.props.loadAccoutData({
                phone: phone,
                token_id: response.data.token
            });
            this.props.setLoading(false);
            // this.props.back();
        });
        return '';
    }

    phoneNice(phone) {
        return phone.substring(0, 3) + ' ' + phone.substring(3, 6) + ' ' + phone.substring(6, 9);
    }

    render() {
        if (this.props && this.props.inMyAccount) {
            // const mobiles = this.state.mobiles.map((item, index) => {
            //     return (
            //         <section className="checkbox-block" key={index}>
            //             <header className="checkbox-block__header">
            //                 <h2>{item.phoneNumber}</h2>
            //             </header>
            //         </section>
            //     )
            // });
            const mobilesFromStorage = JSON.parse(global.localStorage.getItem('mobiles')),
                mobiles = mobilesFromStorage.map((item, index) => {
                return (
                    <section className={"change-phoneNumber" + (item.selected ? " selected" : "")} key={index}>
                        <div className={"button prev" + (index === 0 ? " no-active" : "")}>
                            <div className={"prev-button" + (index === 0 ? " no-active" : "")}
                                 onClick={index > 0 ? this.changeSelected.bind(this, mobilesFromStorage[index - 1]) : undefined}>
                            </div>
                        </div>
                        <div className="phoneNumber">
                            {item.phoneNumber}
                            <div className="on-off-button"
                                 onClick={this.changeMaster.bind(this, item)}>
                                <div className="label">
                                    Główny numer
                                </div>
                                <input type="checkbox" checked={item.master}
                                       onChange={this.changeMaster.bind(this, item)}/>
                                <div className="on-off-button__bg">
                                    <div className="on-off-button__circle"></div>
                                </div>
                            </div>
                        </div>
                        <div className={"button next" + (index === mobilesFromStorage.length ? " no-active" : "")}>
                            <div className={"next-button" + (index === mobilesFromStorage.length ? " no-active" : "")}
                                 onClick={index < JSON.parse(global.localStorage.getItem('mobiles')).length -1 ? this.changeSelected.bind(this, mobilesFromStorage[index + 1]) : undefined}>
                            </div>
                        </div>
                    </section>
                )
            });
            // if (mobiles.length > 5) {
            return (
                <div>
                    {mobiles}
                </div>
            );
            // } else {
            // const PHONE = this.phoneNice((global.localStorage.getItem('phone') ? global.localStorage.getItem('phone') : this.props.phone).toString());
            //     return (
            //         <div onClick={this.props.showScreen} className="user-info__phone">
            //             +48 {PHONE}
            //         </div>
            //     );
            // }
        } else {
            const mobiles = JSON.parse(global.localStorage.getItem('mobiles')).map((item, index) => {
                return (
                    <section className="checkbox-block" key={index}>
                        <header className="checkbox-block__header">
                            <h2>{item.phoneNumber}</h2>
                        </header>
                        <div className="checkbox-block__body">
                            <div className="on-off-button menu-card--size-1-of-1"
                                 style={{marginBottom: '20px'}}
                                 onClick={this.changeMaster.bind(this, item)}>
                                <div className="label">Główny numer</div>
                                <input type="checkbox" checked={item.master}
                                       onChange={this.changeMaster.bind(this, item)}/>
                                <div className="on-off-button__bg">
                                    <div className="on-off-button__circle"></div>
                                </div>
                            </div>
                            {item.phoneNumber != this.props.selectedPhone &&
                                <button onClick={this.changeSelected.bind(this, item)} type="button"
                                        className="menu-card menu-card--size-1-of-1 menu-card--back">
                                    Zarządzaj numerem
                                </button>
                            }
                        </div>
                    </section>
                )
            });
            return (
                <React.Fragment>
                    <h1 className="card--size-1-of-1">Zarządzanie numerami</h1>
                    <section className="card--size-1-of-1 card">
                        <div className="card__body">
                            <div className="packages-info">
                                {mobiles}
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            );
        }
    }
}

export default App;