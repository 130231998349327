import React, { Component } from 'react';
import Layout from '../components/WelcomeLayout';

class App extends Component {

    render() {


        return (

            <Layout title={this.props.title} subtitle={this.props.subtitle} icon="times">
                <footer>
                    <p><a href="/wyloguj" className="link link--olive">Wyloguj</a></p>
                    <p><a href="/" className="link link--olive">Powrót</a></p>
                </footer>
            </Layout>

        );
    }

}

export default App;
