import React, { Component } from 'react';
import Card from './../../components/Card';
import Radio from './../../components/Radio';
import RestApi from "../../helper/RestActions";
import Checkbox from "../../components/CheckboxRedirection";
import CheckboxVoiceMail from "../../components/CheckboxVoiceMail";
import CheckboxPernamentRedirection from "../../components/CheckboxPernamentRedirection";
import Redirections from "../MyAccount";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    isPremiumPacketActive(id){
        if(!this.props.active_packets)
            return false;

        if(this.props.active_packets.indexOf(id)===-1)
            return false;
        return true;
    }


    enablePacket(id){

        this.props.setLoading();

        RestApi.enablePacket(id,(response)=>{
            const data = response.data;
            if(data.status) {
                this.props.loadAccoutData();
            } else {
                this.setState({
                    loading: false,
                    'error': {
                        'title': "Ups! Coś poszło nie tak",
                        'subtitle': data.message,
                    }
                },()=>{
                    setTimeout(()=>global.localStorage.clear(),2000)
                });
            }
        }, (error)=>{
            this.setState({
                loading: false,
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        } );
    }

    disablePacket(id){

        this.props.setLoading();

        RestApi.disablePacket(id,(response)=>{
            const data = response.data;
            if(data.status) {
                this.props.loadAccoutData();
            } else {
                this.setState({
                    loading: false,
                    'error': {
                        'title': data.message,
                        'subtitle': ""
                    }
                },()=>{
                    setTimeout(()=>global.localStorage.clear(),2000)
                });
            }
        }, (error)=>{
            this.setState({
                loading: false,
                'error': {
                    'title': "Ups! Coś poszło nie tak",
                    'subtitle': error.Error
                }
            });
        } );
    }


    render() {

        const PACKETS_RADIO = [];
        const PACKETS_CHECK = [];

        if(this.props.premium_services){
            // const active_packets = [];
            this.props.premium_services.forEach((item,index)=>{
                let checked = this.isPremiumPacketActive(item.id);
                let action = checked?this.disablePacket.bind(this):this.enablePacket.bind(this);

                let operationInProgress = false;
                if(this.props.operations.indexOf(item.id) !== -1)
                    operationInProgress = true;

                let disabled = false;

                //poczta glosowa
                if(item.id == "34") {
                    disabled = false;
                    if(this.props.active_packets.indexOf("27") !== -1){
                        disabled = true;
                    }

                    PACKETS_RADIO.push(<CheckboxVoiceMail
                        item={item}
                        checked={checked}
                        disable={disabled}
                        key={index}
                        loadAccoutData={this.props.loadAccoutData}
                        operationInProgress={operationInProgress}
                        setLoading={this.props.setLoading}/>)
                }
                const regex = /^(Przekierowanie -)/gmi;
                const m = regex.exec(item.name);
                if(m) {
                    if(item.id == "27") {
                        disabled = false;
                        PACKETS_RADIO.push(<CheckboxPernamentRedirection
                            item={item}
                            checked={checked}
                            disable={disabled}
                            key={index}
                            setLoading={this.props.setLoading}
                            loadAccoutData={this.props.loadAccoutData}
                            operationInProgress={operationInProgress}
                            user_data={this.props.user_data}
                        />)
                    }
                    else {

                        if(this.props.active_packets.indexOf("27") !== -1){
                            disabled = true;
                        }

                        const itemClone = Object.assign({},item);

                        let name = itemClone.name.replace('Przekierowanie - ','');
                        itemClone.name = name.charAt(0).toUpperCase() + name.slice(1);

                        PACKETS_CHECK.push(<Checkbox
                            item={itemClone}
                            checked={checked}
                            disable={disabled}
                            click={action}
                            key={index}
                            setLoading={this.props.setLoading}
                            loadAccoutData={this.props.loadAccoutData}
                            user_data={this.props.user_data}
                            operationInProgress={operationInProgress}/>)
                    }
                }
            });
            // if(active_packets.length)
            //     CARDS.push(<Card title="Aktywne pakiety" body={active_packets} />);
        }

        return (
            <React.Fragment>
                <h1 className="card--size-1-of-1">Ustawienie przekierowań</h1>


                {PACKETS_RADIO}

                {PACKETS_CHECK}




            </React.Fragment>
        );
    }
}


export default App;